<template>
  <v-container class="size mt-9">
    <v-subheader class="title mb-4 text-uppercase pa-0">
      <v-icon class="mr-3" @click="$router.back()">mdi-arrow-left</v-icon
      >DETALHES DA ENCOMENDA
    </v-subheader>
    <v-row>
      <v-col cols="12" sm="8">
        <v-card outlined>
          <v-toolbar color="primary" dark elevation="0" dense>
            <v-toolbar-title class="title">ENCOMENDA</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <b>ORIGEM:</b>
                <br />
                {{ dados.origem }}
              </v-col>
              <v-col cols="12" sm="6">
                <b>DESTINO:</b>
                <br />
                {{ dados.destino }}
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <b>Código de Barras:</b>
                <br />
                {{ dados.cod_barras }}
              </v-col>
              <v-col cols="12" sm="6">
                <b>Data/Hora da Emissão:</b>
                <br />
                {{ dados.data_hora }}
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <b>Remente:</b>
                <br />
                {{ dados.remetente }}
              </v-col>
              <v-col cols="12" sm="6">
                <b>Telefone:</b>
                <br />
                {{ dados.fone_remetente }}
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <b>Destinatário:</b>
                <br />
                {{ dados.destinatario }}
              </v-col>
              <v-col cols="12" sm="6">
                <b>Telefone:</b>
                <br />
                {{ dados.fone_destinatario }}
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <b>Carro:</b>
                <br />
                {{ dados.carro }}
              </v-col>
              <v-col cols="12" sm="6">
                <b>Motorista:</b>
                <br />
                {{ dados.motorista }}
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <b>Natureza:</b>
                <br />
                {{ dados.natureza }}
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <b>Forma de Pagamento:</b>
                <br />
                {{ dados.formapagamento }}
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <b>Total:</b>
                <br />
                R$: {{ dados.valor_total }}
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text
            class="text-center"
            v-if="dadosstatus.length > 0 && dadosstatus[0].entregue"
          >
            <v-row>
              <v-col cols="12" sm="12">
                <b>
                  DATA/HORA DA ENTREGA: R$: {{ dados.data_hora_retirada }}
                </b>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card outlined>
          <v-toolbar color="primary" dark elevation="0" dense>
            <v-toolbar-title class="title">STATUS</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row dense>
              <v-col
                cols="12"
                sm="12"
                v-for="(data, i) in dadosstatus"
                :key="i"
              >
                <v-card
                  class="pa-2"
                  outlined
                  :style="`color:${
                    data.status == 'ENTREGUE'
                      ? '#388E3C'
                      : data.status == 'RECEBIDO'
                      ? '#827717'
                      : '#0277BD'
                  }`"
                >
                  <h4>{{ data.data_hora }}</h4>
                  <h4 v-if="data.status != 'ENTREGUE'">
                    {{ data.status == "ENVIADO" ? "Saiu de:" : "Está em:" }}
                    {{ data.localidade }}
                  </h4>
                  <h4>
                    {{ data.status == "ENTREGUE" ? "Chegou em:" : "Para:" }}
                    {{ data.destino }}
                  </h4>
                  <h4>Status: {{ data.status }}</h4>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: ["id"],
  data() {
    return {
      dados: [],
      dadosstatus: [],
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch("activeLoader", true);
      this.$http.get(`/encomendas/caixa/${this.id}`).then((r) => {
        this.dados = r.data[0];
        this.getStatus();
        this.$store.dispatch("activeLoader", false);
      });
    },
    getStatus() {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/encomendas/rastreamento/${this.dados.cod_barras}`)
        .then((r) => {
          this.dadosstatus = r.data;
          this.$store.dispatch("activeLoader", false);
        });
    },
  },
};
</script>
